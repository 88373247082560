import tokenExampleImgPath from '@images/token-example.svg';

import { getCompTranslateKey } from '../translations';

export const getPromptsData = (t: (arg: string) => string) => [
  {
    title: t(getCompTranslateKey('title1')),
    descr: t(getCompTranslateKey('descr1')),
    img: tokenExampleImgPath,
  },
  {
    title: t(getCompTranslateKey('title2')),
    descr: t(getCompTranslateKey('descr2')),
    img: '',
  },
  {
    title: t(getCompTranslateKey('title3')),
    descr: t(getCompTranslateKey('descr3')),
    img: '',
  },
];
