import { Files, Login, Profiles, Welcome } from '@modules';

import { Path } from './path';
import { activeRouteComponent } from './routerStore';

class RouterService {
  private routes: Record<Path, any> = {
    [Path.WELCOME]: Welcome,
    [Path.LOGIN]: Login,
    [Path.FILES]: Files,
    [Path.PROFILES]: Profiles,
  };

  constructor() {
    activeRouteComponent.set(Welcome);
  }

  public navigate(path: Path): void {
    activeRouteComponent.set(this.routes[path]);
  }

  public static instance: RouterService | null;

  static getInstance(): RouterService {
    if (!RouterService.instance) {
      RouterService.instance = new RouterService();
    }
    return RouterService.instance;
  }
}

export const router = RouterService.getInstance();
