<script lang="ts">
  import { t } from '@translation';

  import { Path, router } from '@core/router';

  import { Dropdown, Tooltip } from '@shared/components';
  import { authService } from '@shared/services';
  import { activeProfile, profiles } from '@shared/stores';

  import { getCompTranslateKey } from './translations';

  const isSingleLogin = Object.keys($profiles).length === 1;

  const getInitials = (): string =>
    $activeProfile
      ? $activeProfile.name
          .split(' ')
          .map(n => n[0])
          .join('')
      : '';

  const getImgSrc = (): string =>
    `data:${$activeProfile?.avatar?.contentType};base64, ${$activeProfile?.avatar?.content}`;

  const handleLogout = (isSingle = true): void => {
    authService.logout(isSingle);
  };

  const handleSwitch = (): void => {
    router.navigate(Path.PROFILES);
  };
</script>

<div data-component="HeaderUser" class="wrapper">
  <div class="info">
    <Tooltip placement="bottom" message={$activeProfile?.account?.name || ''}>
      <span class="name">{$activeProfile?.account?.name}</span>
    </Tooltip>

    <Tooltip placement="top" message={$activeProfile?.email || ''}>
      <span class="email">{$activeProfile?.email}</span>
    </Tooltip>
  </div>

  <Dropdown isCloseOnSelection={true} contentWidth={isSingleLogin ? 180 : 'fit-content'}>
    <div slot="trigger">
      {#if $activeProfile?.avatar}
        <img class="avatar" src={getImgSrc()} alt="Avatar" />
      {:else}
        <div class="initials">{getInitials()}</div>
      {/if}
    </div>

    <svelte:fragment slot="content">
      <button class="dropdown-item switch" type="button" on:click={handleSwitch}>
        {$t(getCompTranslateKey('switch'))}
      </button>
      {#if isSingleLogin}
        <button class="dropdown-item logout" type="button" on:click={() => handleLogout()}>
          {$t(getCompTranslateKey('logout'))}
        </button>
      {:else}
        <button class="dropdown-item logout" type="button" on:click={() => handleLogout()}>
          {$t(getCompTranslateKey('logout_single'))}
        </button>
        <button class="dropdown-item logout" type="button" on:click={() => handleLogout(false)}>
          {$t(getCompTranslateKey('logout_all'))}
        </button>
      {/if}
    </svelte:fragment>
  </Dropdown>
</div>

<style src="./styles.scss"></style>
