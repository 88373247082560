import { type IUploadingFile } from '../models';

export const getFullUploadProgress = (files: IUploadingFile[]): number => {
  if (!files.length) return 0;

  const sum = files
    .map(file => file.progress?.percentage || 0)
    .reduce((total, progress) => total + progress, 0);

  return sum / files.length;
};
