<script lang="ts">
  import sanitizeHtml from 'sanitize-html';

  import { t } from '@translation';

  import { Accordion } from '../Accordion';

  import { getPromptsData } from './utils';

  $: promptsData = getPromptsData($t);
</script>

<div data-component="Login/Prompts" class="wrapper">
  {#each promptsData as item}
    <Accordion title={item.title} descr={item.descr}>
      <div class="content">
        <span class="text">{@html sanitizeHtml(item.descr)}</span>
        {#if item.img}
          <img src={item.img} alt={item.title} class="img" />
        {/if}
      </div>
    </Accordion>
  {/each}
</div>

<style src="./styles.scss"></style>
