<script lang="ts">
  import { t } from '@translation';

  import { profiles } from '@shared/stores';

  import { getProfileGroups } from '../../utils';
  import { ProfileListItem } from '../ProfileListItem';

  import { getCompTranslateKey } from './translations';

  const profileGroups = getProfileGroups(Object.values($profiles));
</script>

<div data-component="Profiles/ProfileList" class="wrapper">
  {#each Object.entries(profileGroups) as [email, profileData]}
    <div class="group">
      <p class="descr">
        <span>{$t(getCompTranslateKey('users_found'))}</span>
        <span class="email">{email}:</span>
      </p>

      {#each profileData as profile}
        <ProfileListItem {profile} />
      {/each}
    </div>
  {/each}
</div>

<style src="./styles.scss"></style>
