<script lang="ts">
  import { onMount } from 'svelte';

  import { icons } from './icons';

  export let name: keyof typeof icons;
  export let color: string = 'unset';
  export let size: number;

  $: data = icons[name] as string;
  let objectEl: HTMLObjectElement;

  onMount(() => {
    objectEl.onload = () => {
      const style = getComputedStyle(document.body);
      const styleColor = style.getPropertyValue(color);
      const svgEl = objectEl?.getSVGDocument()?.children[0] as SVGElement;
      svgEl.style.color = styleColor;
    };
  });
</script>

<object bind:this={objectEl} {data} width={size} height={size} aria-labelledby="icon"></object>

<style src="./styles.scss" lang="scss"></style>
