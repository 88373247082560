<script lang="ts">
  import { Icon } from '@shared/components';

  export let title = '';
  export let descr = '';

  $: active = false;
  $: height = 0;
  let content: HTMLDivElement;

  const toggleAccordion = (): void => {
    active = !active;
    height = active ? content.scrollHeight : 0;
  };
</script>

<div data-component="Login/Accordion" class="wrapper">
  <div
    role="button"
    tabindex="0"
    class="accordion"
    class:active
    on:click={toggleAccordion}
    on:keydown={toggleAccordion}
  >
    <div class="title-wrapper">
      <p class="title">{title}</p>
      <button class="expand-btn" class:expand-btn-active={active}>
        <Icon name="arrow" color="--blue-100" size={18} />
      </button>
    </div>

    {#if !active}
      <span class="text">{descr}</span>
    {/if}
  </div>
  <div bind:this={content} style={`max-height: ${height}`} class="content">
    <slot />
  </div>
</div>

<style src="./styles.scss"></style>
