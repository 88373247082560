import { getTranslatePath, translations } from '@translation';

const MODULE_TRANSLATION_KEY = 'modules/profiles';

const data = {
  en: {
    add: 'Add account',
  },
  de: {
    add: 'Konto hinzufügen',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
