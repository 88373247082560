import { get } from 'svelte/store';

import { AxiosError } from 'axios';

import { changeLanguage, t } from '@translation';

import { extractResponse } from '@core/api';
import { Path, router } from '@core/router';

import { loginApi } from '@shared/apis';
import { LOCALESTORAGE_KEYS } from '@shared/constants';
import { toasts } from '@shared/features';
import { type IProfile, type IServiceData } from '@shared/models';
import { activeProfile, profiles } from '@shared/stores';

class AuthService {
  constructor() {}

  public getMe({ payload: token, onSuccess, onError }: IServiceData<string>): void {
    loginApi
      .getMe(token)
      .then(res => {
        const profileData = extractResponse(res)?.item;

        changeLanguage(profileData.language);
        activeProfile.set(profileData);
        localStorage.setItem(LOCALESTORAGE_KEYS.ActiveToken, token);
        this.handleStorageProfiles(token, profileData);

        onSuccess?.(profileData);
      })
      .catch(err => {
        const profilesData = get(profiles);

        if (profilesData) {
          const profilesDataCopy = { ...profilesData };
          delete profilesDataCopy[token];

          localStorage.setItem(LOCALESTORAGE_KEYS.ProfilesData, JSON.stringify(profilesDataCopy));
          profiles.set(profilesDataCopy);
        }

        if (err.code === AxiosError.ERR_BAD_REQUEST) {
          toasts.send({
            message: get(t)('errors.not-possible-auth'),
            type: 'error',
          });

          const storageActiveToken = localStorage.getItem(LOCALESTORAGE_KEYS.ActiveToken);
          if (storageActiveToken === token) {
            localStorage.removeItem(LOCALESTORAGE_KEYS.ActiveToken);
            activeProfile.set(null);
          }
        } else {
          toasts.send({
            message: get(t)('errors.unknown'),
            type: 'error',
          });
        }
        onError?.(err);
      });
  }

  private handleStorageProfiles(token: string, user: IProfile) {
    const profilesData = get(profiles);

    if (profilesData) {
      const updatedProfilesData = {
        ...profilesData,
        [token]: user,
      };
      localStorage.setItem(LOCALESTORAGE_KEYS.ProfilesData, JSON.stringify(updatedProfilesData));
      profiles.set(updatedProfilesData);
    } else {
      const userData = {
        [token]: user,
      };
      localStorage.setItem(LOCALESTORAGE_KEYS.ProfilesData, JSON.stringify(userData));
      profiles.set(userData);
    }
  }

  public logout(isSingle = true): void {
    const activeToken = localStorage.getItem(LOCALESTORAGE_KEYS.ActiveToken) || '';

    const storageUsersData = { ...get(profiles) };

    if (isSingle) {
      delete storageUsersData[activeToken];
      profiles.update(prev => {
        const prevCopy = { ...prev };
        delete prevCopy[activeToken];
        return prevCopy;
      });
      localStorage.setItem(LOCALESTORAGE_KEYS.ProfilesData, JSON.stringify(get(profiles)));
    } else {
      localStorage.removeItem(LOCALESTORAGE_KEYS.ProfilesData);
      profiles.set({});
    }

    localStorage.removeItem(LOCALESTORAGE_KEYS.ActiveToken);

    activeProfile.set(null);

    if (Object.keys(storageUsersData).length && isSingle) {
      router.navigate(Path.PROFILES);
    } else {
      router.navigate(Path.WELCOME);
    }
  }

  public static instance: AuthService | null;

  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }
}

export const authService = AuthService.getInstance();
