import axios, {
  type AxiosInstance,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { LOCALESTORAGE_KEYS } from '@shared/constants';

class Api {
  constructor() {}

  public static instance: AxiosInstance | null;

  static getInstance(): AxiosInstance {
    if (!Api.instance) {
      Api.instance = axios.create({
        baseURL: `${process.env.API_URL}/outlook/v1`,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });

      Api.instance.interceptors.request.use(
        (config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
          const token = localStorage.getItem(LOCALESTORAGE_KEYS.ActiveToken);

          const newConfig = { ...config };

          if (!config.headers?.Authorization) {
            newConfig.headers.Authorization = token;
          }

          if (config.params) {
            newConfig.params = decamelizeKeys(config.params);
          }

          if (config.data) {
            newConfig.data = decamelizeKeys(config.data);
          }

          return newConfig;
        },
      );

      Api.instance.interceptors.response.use((response: AxiosResponse) => {
        if (response.data && response.headers['content-type'] === 'application/json') {
          response.data = camelizeKeys(response.data);
        }
        return response;
      });
    }
    return Api.instance;
  }
}

export const api = Api.getInstance();
