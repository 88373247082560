import { getTranslatePath, translations } from '@translation';

const MODULE_TRANSLATION_KEY = 'modules/login/login_form';

const data = {
  en: {
    submit: 'Submit token',
    token: 'Token',
  },
  de: {
    submit: 'Token einreichen',
    token: 'Token',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
