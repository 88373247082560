<script lang="ts">
  import filesImgPath from '@images/files.svg';
  import { t } from '@translation';

  import { toasts } from '@shared/features';

  import { uploadFilesService } from '../../services';
  import { uploadingFiles } from '../../stores';

  import { getCompTranslateKey } from './translations';
  import { isFileTypeAcceptable } from './utils';

  $: isDragging = false;
  let fileInputRef: HTMLInputElement;

  const handleDrag = (e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      isDragging = true;
    } else if (e.type === 'dragleave') {
      isDragging = false;
    }
  };

  const handleDrop = (e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    isDragging = false;
    let files = e.dataTransfer?.files;

    if (!files) return;

    const filesList = getCheckedFiles(files);

    if (filesList.length > 0) {
      onFilesAdded(filesList);
    }
  };

  const getCheckedFiles = (files: FileList): File[] => {
    let _files = Array.from(files);
    let acceptableFiles = [];

    for (let index = 0; index < _files.length; index++) {
      const element = _files[index];

      if (!isFileTypeAcceptable(element.name)) {
        toasts.send({
          message: `${$t(getCompTranslateKey('not_acceptable_file'))}: ${element.name}`,
          type: 'error',
        });
        continue;
      }

      acceptableFiles.push(element);
    }

    return acceptableFiles;
  };

  const handleChange = (e: Event): void => {
    let files = (e.target as HTMLInputElement)?.files;

    if (!files) {
      return;
    }

    const filesList = getCheckedFiles(files);

    if (filesList.length) {
      onFilesAdded(filesList);
    }

    if (fileInputRef) fileInputRef.value = '';
  };

  const onFilesAdded = (files: File[]): void => {
    uploadFilesService.uploadFilesToCache([...files]);
  };
</script>

<div
  data-component="Files/Dropzone"
  on:dragenter={handleDrag}
  class="wrapper"
  class:uploaded={!!$uploadingFiles.length}
  role="button"
  tabindex="-1"
>
  <input
    id="file-input"
    bind:this={fileInputRef}
    class="input"
    type="file"
    multiple
    on:change={handleChange}
  />

  <label for="file-input" class="dropzone" class:dragging={isDragging}>
    <div class="content">
      <img src={filesImgPath} alt="files" />

      <span class="title">
        <span>{$t(getCompTranslateKey('dnd'))}</span>
        <button class="upload-btn" type="button" on:click={() => fileInputRef.click()}>
          {$t(getCompTranslateKey('choseFile'))}
        </button>
        <span>{$t(getCompTranslateKey('upload'))}</span>
      </span>

      <span class="subtitle" data-type="text">
        {$t(getCompTranslateKey('size'))}
      </span>
    </div>
  </label>

  {#if isDragging}
    <div
      role="button"
      tabindex="-1"
      class="drag-wrapper"
      on:dragenter={handleDrag}
      on:dragleave={handleDrag}
      on:dragover={handleDrag}
      on:drop={handleDrop}
    />
  {/if}
</div>

<style src="./styles.scss"></style>
