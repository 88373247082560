export const getFileIconName = (filename: string): string => {
  const extension = filename.split('.').pop()?.toLowerCase();
  let iconName;

  switch (extension) {
    case 'pdf':
      iconName = 'pdfType';
      break;
    case 'xls':
    case 'xlsx':
    case 'csv':
      iconName = 'excelType';
      break;
    case 'ppt':
    case 'pptx':
      iconName = 'powerpointType';
      break;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      iconName = 'imageType';
      break;
    case 'doc':
    case 'docx':
      iconName = 'wordType';
      break;
    case 'zip':
      iconName = 'zipType';
      break;
    default:
      iconName = 'fileType';
  }

  return iconName;
};
