<script lang="ts">
  import { Path, router } from '@core/router';

  import { LoadingSpinner } from '@shared/components';
  import { type IProfile } from '@shared/models';
  import { authService } from '@shared/services';
  import { profiles } from '@shared/stores';

  export let profile: IProfile;

  $: isLoading = false;

  const handleClick = (): void => {
    isLoading = true;

    const activeProfileData = Object.entries($profiles).find(
      item => item[1].permalink === profile.permalink,
    ) as [string, IProfile];

    const [token] = activeProfileData;

    authService.getMe({
      payload: token,
      onSuccess: () => router.navigate(Path.FILES),
      onError: () => (isLoading = false),
    });
  };
</script>

<div
  data-component="Profiles/ProfileListItem"
  class="wrapper"
  on:click={handleClick}
  on:keydown={handleClick}
  role="button"
  tabindex="0"
  class:loading={isLoading}
>
  <p class="name">{profile.account?.name}</p>
  <p class="address">
    <span>{profile.account?.street}</span>
    <span>{profile.account?.zip} {profile.account?.city}</span>
    <span>{profile.account?.country}</span>
  </p>
  {#if isLoading}
    <div class="spinner">
      <LoadingSpinner size={30} strokeWidth={4} />
    </div>
  {/if}
</div>

<style src="./styles.scss"></style>
