import { getTranslatePath, translations } from '../../../../../../translation';

const MODULE_TRANSLATION_KEY = 'modules/files/link_info_form/expires_in_dropdown_control';

const data = {
  en: {
    day: '(1)[{{count}} day];(2-inf)[{{count}} days]',
    expires_after: 'Expires after',
  },
  de: {
    day: '(1)[{{count}} Tag];(2-inf)[{{count}} Tagen]',
    expires_after: 'Läuft ab nach',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
