import { getTranslatePath, translations } from '@translation';

const MODULE_TRANSLATION_KEY = 'modules/login';

const data = {
  en: {
    title: 'Add account',
    description: 'To add an account, you need to enter your individual token.',
  },
  de: {
    title: 'Konto hinzufügen',
    description: 'Um ein Konto hinzuzufügen, müssen Sie Ihren individuellen Token eingeben.',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
