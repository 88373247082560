<script lang="ts">
  import * as yup from 'yup';
  import { ValidationError } from 'yup';

  import { t } from '@translation';

  import {
    Button,
    Checkbox,
    Icon,
    InputPassword,
    InputText,
    ProgressRing,
    Textarea,
  } from '@shared/components';
  import { officeService } from '@shared/services';
  import { contextFiles } from '@shared/stores';
  import { formatBites, getFormErrors, getInitialFormErrors } from '@shared/utils';

  import { LinkType } from '../../constants';
  import { linkService, uploadFilesService } from '../../services';
  import { link, uploadingFiles } from '../../stores';
  import { getFullUploadProgress } from '../../utils';

  import { ExpiresInDropdownControl, InfoTooltip } from './components';
  import { getCompTranslateKey } from './translations';

  $: fullUploadProgress = getFullUploadProgress($uploadingFiles);

  let isLoading = false;

  const { title, message, password, expiresIn, downloadLimit, emailNotification } = $link;

  const values = {
    title,
    message,
    password,
    expiresIn,
    downloadUnlimit: !downloadLimit,
    emailNotification,
    twoWay: false,
  };

  let errors = getInitialFormErrors(values);

  const schema = yup.object().shape({
    title: yup.string().required($t('validation.required')),
    message: yup.string(),
    password: yup.string(),
    expiresIn: yup.number(),
    downloadUnlimit: yup.boolean(),
    emailNotification: yup.boolean(),
    twoWay: yup.boolean(),
  });

  const addLinkToEmail = (): void => {
    const filesSize = $uploadingFiles.reduce((acc, file) => acc + file.size, 0);

    const emailPayload = {
      link: `${process.env.API_URL}/quickshare/${$link.permalink}`,
      filesCount: $uploadingFiles.length,
      filesSize: formatBites(filesSize),
      expiresIn: values.expiresIn,
    };

    officeService.addLinkToEmail({
      payload: emailPayload,
      onSuccess: () => {
        linkService.resetLink();
        uploadFilesService.resetFiles();
        isLoading = false;
      },
    });
  };

  const handleSubmit = (): void => {
    errors = {};

    schema
      .validate(values, { abortEarly: false })
      .then(() => {
        isLoading = true;

        const { title, message, password, expiresIn, downloadUnlimit, emailNotification, twoWay } =
          values;

        const payload = {
          title,
          message,
          password,
          expiresIn,
          emailNotification,
          linkType: twoWay ? LinkType.TWO_WAY : LinkType.SHARE,
          downloadLimit: !downloadUnlimit,
          permalink: $link.permalink,
        };

        linkService.finalizeLinkCreation({
          payload,
          onSuccess: () => {
            addLinkToEmail();

            if ($contextFiles.length) {
              officeService.removeEmailAttachedFiles();
              contextFiles.set([]);
            }
          },
          onError: () => (isLoading = true),
        });
      })
      .catch(err => (errors = getFormErrors(err as ValidationError)));
  };
</script>

<form on:submit|preventDefault={handleSubmit} class="form" data-component="Files/LinkInfoForm">
  <InputText
    bind:value={values.title}
    name="title"
    error={errors?.title}
    label={$t(getCompTranslateKey('title'))}
  />

  <Textarea
    bind:value={values.message}
    name="message"
    error={errors?.message}
    label={$t(getCompTranslateKey('note'))}
    isOptional={true}
  />

  <InputPassword
    bind:value={values.password}
    name="password"
    error={errors?.password}
    isOptional={true}
  />

  <ExpiresInDropdownControl bind:value={values.expiresIn} name="expiresIn" />

  <div class="checkbox-wrapper">
    <Checkbox
      name="downloadUnlimit"
      label={$t(getCompTranslateKey('unlimited_downloads'))}
      bind:checked={values.downloadUnlimit}
      error={errors?.downloadUnlimit}
    />

    <InfoTooltip message={$t(getCompTranslateKey('tooltip_download'))} />
  </div>

  <div class="checkbox-wrapper">
    <Checkbox
      name="emailNotification"
      label={$t(getCompTranslateKey('notifications'))}
      bind:checked={values.emailNotification}
      error={errors?.emailNotification}
    />

    <InfoTooltip message={$t(getCompTranslateKey('tooltip_notifications'))} />
  </div>

  <div class="checkbox-wrapper">
    <Checkbox
      name="twoWay"
      label={$t(getCompTranslateKey('two_way'))}
      bind:checked={values.twoWay}
      error={errors?.twoWay}
    />

    <InfoTooltip message={$t(getCompTranslateKey('tooltip_two_way'))} />
  </div>

  <div class="footer">
    <div class="footer-info">
      {#if fullUploadProgress < 100}
        <ProgressRing size={30} percentage={fullUploadProgress} />
        <p class="footer-text">
          <span class="bold">{$t(getCompTranslateKey('waiting_upload'))}</span>
          <span>{$t(getCompTranslateKey('waiting_action'))}</span>
        </p>
      {:else}
        <Icon name="checkMark" color="--green-100" size={26} />
        <p class="footer-text">
          <span class="bold">{$t(getCompTranslateKey('uploaded_successfully'))}</span>
        </p>
      {/if}
    </div>

    <Button
      type="submit"
      model="primary"
      size="medium"
      {isLoading}
      disabled={fullUploadProgress < 100}
    >
      {$t(getCompTranslateKey('done'))}
    </Button>
  </div>
</form>

<style src="./styles.scss"></style>
