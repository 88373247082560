<script lang="ts">
  import { t } from '@translation';

  import { Button } from '@shared/components';

  export let onCancel: () => void;
</script>

<div data-component="HeaderCancel" class="wrapper">
  <Button type="button" model="primary-text" size="medium" onClick={onCancel}>
    {$t('actions.cancel')}
  </Button>
</div>

<style src="./styles.scss"></style>
