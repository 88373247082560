<script lang="ts">
  import { i18nInitialize } from '@translation';

  import { Layout } from '@core/components';

  import { activeRouteComponent, Path, router } from '@router';

  import { LoadingSpinner } from '@shared/components';
  import { LOCALESTORAGE_KEYS } from '@shared/constants';
  import { Toasts } from '@shared/features';
  import { authService, officeService } from '@shared/services';
  import { contextFiles, profiles } from '@shared/stores';

  $: isLoadingAppInfo = true;
  $: isLoadingPresetInfo = true;

  const handleI18nInitialize = async (): Promise<void> => {
    const lang = officeService.getOfficeLanguage();
    await i18nInitialize(lang);
  };

  Office.onReady(async () => {
    await handleI18nInitialize();

    officeService.handleInitializationContext({
      onSuccess: files => {
        contextFiles.set(files);
        isLoadingPresetInfo = false;
      },
      onError: () => (isLoadingPresetInfo = false),
    });

    const storageActiveToken = localStorage.getItem(LOCALESTORAGE_KEYS.ActiveToken);

    if (storageActiveToken) {
      authService.getMe({
        payload: storageActiveToken,
        onSuccess: () => {
          router.navigate(Path.FILES);
          isLoadingAppInfo = false;
        },
        onError: () => (isLoadingAppInfo = false),
      });
    } else if (Object.keys($profiles).length) {
      router.navigate(Path.PROFILES);
      isLoadingAppInfo = false;
    } else {
      isLoadingAppInfo = false;
    }
  });
</script>

<div data-component="Quickshare" class="wrapper">
  {#if isLoadingAppInfo || isLoadingPresetInfo}
    <div class="loader-wrapper">
      <LoadingSpinner />
    </div>
  {:else}
    <Layout>
      <svelte:component this={$activeRouteComponent} />
    </Layout>
  {/if}

  <Toasts />
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 100%;
  }

  .loader-wrapper {
    @include app-loading-wrapper;
  }
</style>
