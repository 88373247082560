// import { get } from 'svelte/store';

// import { t } from '@translation';

import type { IEmailData } from '../models';

// import { formatUrltoBase64 } from './formatUrltoBase64';

export const generateEmailLinkHtml = async (payload: IEmailData): Promise<string> => {
  // const imgUrl = await formatUrltoBase64('../../../../assets/auditi.png');

  return payload.link;

  // temporarely disable HTML embed due to 'spam' concerns
  // return `
  //   <div contenteditable="false">
  //     <table style="font-family: Arial, sans-serif; box-sizing: border-box; background-color: white; border-radius: 8px; padding: 20px; border: 1px solid #D3D9E3; width: 100%; max-width: 550px;" cellpadding="0" cellspacing="0">
  //       <tr>
  //         <td>
  //           <div style="color: #052861; font-size: 16px; line-height: 22px; margin-bottom: 12px;">
  //              ${get(t)('email_html.title')}
  //           </div>

  //           <div style="font-size: 18px; line-height: 22px; font-weight: bold; color: #052861; margin-bottom: 4px;">
  //             ${get(t)('email_html.size', {
  //               postProcess: 'interval',
  //               count: payload.filesCount,
  //               size: payload.filesSize,
  //             })}
  //           </div>
  //           <div style="font-size: 14px; line-height: 22px; margin-bottom: 12px; color: #5B759F;">
  //             ${get(t)('email_html.availability', {
  //               postProcess: 'interval',
  //               count: payload.expiresIn,
  //             })}
  //           </div>

  //           <table style="width: fit-content;" cellspacing="0" cellpadding="0">
  //             <tr>
  //               <td style="border-radius: 8px; text-align: center;">
  //                 <a
  //                   href="${payload.link}"
  //                   target="_blank"
  //                   style="display: flex; box-sizing: border-box; font-size: 16px; color: #fff; text-decoration: none; padding: 10px 12px; border-radius: 8px; background: #156DF9;">
  //                   ${get(t)('email_html.access_files')}
  //                 </a>
  //               </td>
  //             </tr>
  //           </table>
  //         </td>
  //       </tr>
  //     </table>

  //     <table style="box-sizing: border-box; width: 100%; max-width: 550px;">
  //        <tr>
  //           <td style="border-radius: 8px; text-align: left;">
  //             <a href="https://auditi.de" style="cursor: pointer">
  //               <img
  //                 style="width: 32px;"
  //                 src=${imgUrl}
  //               />
  //             </a>
  //           </td>
  //           <td style="font-size: 12px; color: #5B759F; text-align: right;">
  //             ${get(t)('email_html.provided_by')} <a href="https://auditi.de" style="color: #156DF9; text-decoration: none; font-weight: bold;">auditi.de</a>
  //           </td>
  //        </tr>
  //     </table>
  //   </div>
  // `;
};
