<script lang="ts">
  import { Icon, Tooltip } from '@shared/components';

  export let message: string;

  $: isActive = false;
  $: iconName = isActive ? 'infoMark' : 'infoOutlined';
</script>

<Tooltip placement="top" size="medium" on:toggle={e => (isActive = e.detail.isOpen)} {message}>
  <Icon name={iconName} color="--blue-100" size={18} />
</Tooltip>
