import { get } from 'svelte/store';

import AwsS3 from '@uppy/aws-s3';
import Uppy, { type Body, type Meta, type UppyFile } from '@uppy/core';

import { t } from '@translation';

import { extractResponse } from '@core/api';

import { LOCALESTORAGE_KEYS } from '@shared/constants';
import { toasts } from '@shared/features';
import type { IServiceData } from '@shared/models';

import { linkApi } from '../apis';
import { type IFileMeta, type IUploadingFile, type TLinkFileStatused } from '../models';
import { link, uploadingFiles } from '../stores';

import { linkService } from './linkService';

class UploadFilesService {
  private uppy: Uppy;

  constructor() {}

  public setupUppy(): void {
    this.uppy = this.initializeUppy();

    this.setAws();
    this.initializeUppyEvents();
  }

  private setAws(): void {
    const storageActiveToken = localStorage.getItem(LOCALESTORAGE_KEYS.ActiveToken) as string;

    this.uppy.use(AwsS3, {
      endpoint: `${process.env.API_URL}/minio`,
      headers: {
        Authorization: storageActiveToken,
      },
      shouldUseMultipart: () => false,
    });
  }

  private initializeUppy(): Uppy {
    return new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxFileSize: 2000 * 1024 * 1024, // 2000 MB
        maxNumberOfFiles: 500,
        minNumberOfFiles: 1,
      },
    });
  }

  private initializeUppyEvents(): void {
    this.uppy.on('file-added', file => {
      const linkData = get(link);
      if (!linkData) return;

      if (!linkData.title && !linkData.permalink) {
        const updatedLinkData = {
          ...linkData,
          title: file.name ?? '',
        };

        if (updatedLinkData && !updatedLinkData?.permalink) {
          linkService.createLink({
            payload: updatedLinkData,
          });
        }
      }

      this.setUploadingFiles();
    });

    this.uppy.on('upload-progress', () => {
      this.setUploadingFiles();
    });

    this.uppy.on('upload-success', () => {
      this.setUploadingFiles();
    });

    this.uppy.on('complete', () => {
      this.setUploadingFiles();
      // Interval is used because here could be a bug
      // if link creation request was not completed and
      // linkData.permalink is still empty.
      // It's impossible to track both:
      // upload event fire and link creation request completion.
      const interval = setInterval(() => {
        const linkData = get(link);
        if (linkData.permalink) {
          void this.addLinkFiles();
          clearInterval(interval);
        }
      }, 500);
    });
  }

  private setUploadingFiles(): void {
    const files = (this.uppy.getFiles() as unknown as UppyFile<Meta, Body>[]).map(file => {
      const storeFile = get(uploadingFiles).find(storeFile => storeFile.name === file.name);

      return {
        cacheId: file.id,
        cacheKey: file.uploadURL?.match(/cache\/([a-f0-9]{32}\.[a-z]{3,4})/)?.[1],
        name: file.name,
        size: file.size,
        type: file.type,
        progress: {
          uploadStarted: file.progress.uploadStarted,
          uploadComplete: file.progress.uploadComplete,
          percentage: file.progress.percentage,
        },
        permalink: storeFile?.permalink ?? '',
        stored: storeFile?.stored ?? false,
        virus: storeFile?.virus ?? false,
      };
    }) as unknown as IUploadingFile[];

    uploadingFiles.set(files);
  }

  public uploadFilesToCache(files: File[]) {
    files.forEach(file => {
      try {
        this.uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file,
        });
      } catch (error) {
        if (error.isRestriction) {
          toasts.send({
            message: error.message,
            type: 'error',
          });
        } else {
          toasts.send({
            message: get(t)('errors.unknown'),
            type: 'error',
          });
        }
      }
    });
  }

  private async addLinkFiles(): Promise<void> {
    const files = get(uploadingFiles).filter(file => !file.permalink);

    const promises = files.map(file => {
      return new Promise((resolve, reject) => {
        const payload = {
          id: get(link).permalink,
          file,
        };
        this.addLinkFile({
          payload,
          onSuccess: res => resolve(res),
          onError: err => reject(err),
        });
      });
    });

    const result = await Promise.allSettled(promises);

    const errorFiles = files.filter((_, i) => result[i].status === 'rejected');
    errorFiles.forEach(file => this.uppy.removeFile(file.cacheId));

    const uploadingFilesData = get(uploadingFiles).filter(
      file => !errorFiles.some(errorFile => errorFile.cacheId === file.cacheId),
    );
    uploadingFiles.set(uploadingFilesData);

    const statusCheckingFilesData = get(uploadingFiles).filter(item =>
      files.find(file => file.cacheId === item.cacheId),
    );
    this.checkFilesStatus(statusCheckingFilesData);
  }

  private addLinkFile({
    onSuccess,
    onError,
    payload,
  }: IServiceData<{
    id: string;
    file: IUploadingFile;
  }>) {
    const { id, file } = payload;

    const fileMeta: IFileMeta = {
      id: file.cacheKey,
      storage: 'pbc_cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mimeType: file.type,
      },
    };

    linkApi
      .addLinkFile({ id, fileMeta })
      .then(res => {
        const data = extractResponse(res)?.item;

        uploadingFiles.update(prev =>
          prev.map(item =>
            item.name === file.name
              ? {
                  ...item,
                  permalink: data.permalink,
                  stored: data.stored,
                  virus: data.virus,
                }
              : item,
          ),
        );
        onSuccess?.(res);
      })
      .catch(err => {
        toasts.send({
          message: get(t)('errors.unknown'),
          type: 'error',
        });
        onError?.(err);
      });
  }

  private checkFilesStatus(uploadingFilesData: IUploadingFile[]): void {
    let time = 500;

    const requestAction = () => {
      const processingFiles = get(uploadingFiles).filter(file =>
        uploadingFilesData.some(
          uploadingFile => uploadingFile.permalink === file.permalink && !file.stored,
        ),
      );

      if (processingFiles.length) {
        const payload = {
          id: get(link).permalink,
          ids: processingFiles.map(file => file.permalink),
        };
        this.getLinkFilesStatus({ payload });
        time *= 2;
        setTimeout(requestAction, time);
      }
    };

    setTimeout(requestAction, time);
  }

  private getLinkFilesStatus({
    onSuccess,
    onError,
    payload,
  }: IServiceData<{
    id: string;
    ids: string[];
  }>) {
    linkApi
      .getLinkFilesStatus(payload)
      .then(res => {
        const data = extractResponse(res)?.items;

        uploadingFiles.update(prev =>
          prev.map(prevFile => {
            const fileData = data?.find(
              (item: TLinkFileStatused) => item.permalink === prevFile.permalink,
            );

            return fileData
              ? {
                  ...prevFile,
                  stored: fileData.stored,
                  virus: fileData.virus,
                }
              : prevFile;
          }),
        );
        onSuccess?.(res);
      })
      .catch((err: unknown) => {
        toasts.send({
          message: get(t)('errors.unknown'),
          type: 'error',
        });
        onError?.(err);
      });
  }

  public removeFile(cacheId: string) {
    this.uppy.removeFile(cacheId);
    this.setUploadingFiles();
  }

  public resetFiles(): void {
    linkService.resetLink();
    uploadingFiles.set([]);
    this.removeAllUppyFiles();
  }

  public removeAllUppyFiles(): void {
    this.uppy.getFiles().forEach(file => {
      this.uppy.removeFile(file.id);
    });
    this.setUploadingFiles();
  }

  public static instance: UploadFilesService | null;

  static getInstance(): UploadFilesService {
    if (!UploadFilesService.instance) {
      UploadFilesService.instance = new UploadFilesService();
    }
    return UploadFilesService.instance;
  }
}

export const uploadFilesService = UploadFilesService.getInstance();
