import { getTranslatePath, translations } from '@translation';

const MODULE_TRANSLATION_KEY = 'modules/login/prompts';

const data = {
  en: {
    title1: 'Where can I find my token?',
    descr1:
      'On the Auditi platform, navigate to Quickshare and find your token in the “Quickshare Outlook Plugin” info box on the sidebar, which should look as follows:',
    title2: 'Can I add multiple accounts?',
    descr2:
      'Yes, multiple accounts can be added after you have logged in with the first account. To add more accounts, click on your avatar here in the Outlook plugin and select "Switch profile". There you will find an "Add Account" button. Please note that each account (each email address) has a unique token.',
    title3: 'My token is not working',
    // TODO investigate email link handling
    descr3:
      'Please make sure that the token you are entering is exactly the same as the one we have provided to you through the Auditi platform. If you are still experiencing issues and the token does not work, please contact support@auditi.com. We will assist you as soon as possible.',
  },
  de: {
    title1: 'Wo kann ich meine Wertmarke finden?',
    descr1:
      'Navigieren Sie auf der Auditi-Plattform zu Quickshare und suchen Sie Ihr Token in der Infobox ‚Quickshare Outlook Plugin‘ in der Seitenleiste, die wie folgt aussehen sollte:',
    title2: 'Kann ich mehrere Konten hinzufügen?',
    descr2:
      'Ja, es können mehrere Konten hinzugefügt werden, nachdem Sie sich mit dem ersten Konto angemeldet haben. Um weitere Konten hinzuzufügen, klicken Sie hier im Outlook-Plugin auf Ihren Avatar und wählen Sie „Profil wechseln“. Dort finden Sie eine Schaltfläche „Konto hinzufügen“. Bitte beachten Sie, dass jedes Konto (jede E-Mail-Adresse) ein eindeutiges Token hat.',
    title3: 'Mein Token funktioniert nicht',
    // TODO investigate email link handling
    descr3:
      'Bitte vergewissern Sie sich, dass der Token, den Sie eingeben, genau mit dem übereinstimmt, den wir Ihnen über die Auditi-Plattform zur Verfügung gestellt haben. Wenn Sie weiterhin Probleme haben und der Token nicht funktioniert, wenden Sie sich bitte an support@auditi.com. Wir werden Ihnen so schnell wie möglich helfen.',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
