<script lang="ts">
  import { LoadingSpinner } from '@shared/components';

  export let type: 'submit' | 'button' = 'submit';
  export let isLoading: boolean = false;
  export let disabled: boolean = false;
  export let size: 'content' | 'medium' | 'small' | 'extra-small' = 'content';
  export let model:
    | 'primary'
    | 'primary-tonal'
    | 'primary-text'
    | 'error'
    | 'error-tonal'
    | 'error-text';
  export let contentType: 'text' | 'icon' = 'text';
  export let onClick: () => void = () => {};
</script>

<button
  data-component="Button"
  on:click={onClick}
  {type}
  disabled={disabled || isLoading}
  class={`${model} ${size} ${contentType}`}
>
  <span class="content" class:content-hidden={isLoading}>
    <slot />
  </span>
  {#if isLoading}
    <span class="loading-wrapper">
      <LoadingSpinner size={16} strokeWidth={3} color="--primary-200" />
    </span>
  {/if}
</button>

<style src="./styles.scss" lang="scss"></style>
