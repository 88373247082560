import { getTranslatePath, translations } from '@translation';

const MODULE_TRANSLATION_KEY = 'modules/files/files_list_item';

const data = {
  en: {
    checking: 'Checking for viruses...',
    virus_found: 'Virus was found',
  },
  de: {
    checking: 'Überprüfung auf Viren...',
    virus_found: 'Virus wurde gefunden',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
