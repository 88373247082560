import { getTranslatePath, translations } from '@translation';

const MODULE_TRANSLATION_KEY = 'shared/features/header/header_user';

const data = {
  en: {
    switch: 'Switch profile',
    logout: 'Logout',
    logout_single: 'Log Out from Current Account',
    logout_all: 'Log Out from All Accounts',
  },
  de: {
    switch: 'Profil wechseln',
    logout: 'Abmelden',
    logout_single: 'Abmelden vom aktuellen Konto',
    logout_all: 'Von allen Konten abmelden',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
