import { getTranslatePath, translations } from '@translation';

const MODULE_TRANSLATION_KEY = 'modules/files/dropzone';

const data = {
  en: {
    dnd: 'Drag & drop or',
    choseFile: 'choose file',
    upload: 'to upload',
    size: '2 GB max. file size',
    not_acceptable_file: 'This file type is not supported',
  },
  de: {
    dnd: 'Drag & Drop oder',
    choseFile: 'Datei auswählen',
    upload: 'zum Hochladen',
    size: '2 GB max. Dateigröße',
    not_acceptable_file: 'Dieses Dateiformat ist nicht erlaubt',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
