<script lang="ts">
  import { t } from '@translation';

  import { LoginForm, Prompts } from './components';
  import { getCompTranslateKey } from './translations';
</script>

<div data-component="Login" class="wrapper">
  <div class="info-item">
    <p class="title">
      {$t(getCompTranslateKey('title'))}
    </p>
    <p class="text">
      {$t(getCompTranslateKey('description'))}
    </p>
  </div>

  <LoginForm />

  <Prompts />
</div>

<style src="./styles.scss"></style>
