import { get } from 'svelte/store';

import { t } from '@translation';

import { extractResponse } from '@core/api';

import { toasts } from '@shared/features';
import { type IServiceData } from '@shared/models';

import { linkApi } from '../apis';
import { linkInitValue } from '../constants';
import { type ILink } from '../models';
import { link } from '../stores';

class LinkService {
  constructor() {}

  public createLink({ payload, onSuccess, onError }: IServiceData<ILink>): void {
    link.set(payload);

    linkApi
      .createLink(payload)
      .then(res => {
        const resData = extractResponse(res).item;

        link.update(data => ({
          ...data,
          permalink: resData.permalink,
        }));

        onSuccess?.(resData);
      })
      .catch(err => {
        toasts.send({
          message: get(t)('errors.unknown'),
          type: 'error',
        });

        onError?.(err);
      });
  }

  public finalizeLinkCreation({ payload, onSuccess, onError }: IServiceData<ILink>): void {
    linkApi
      .finalizeLinkCreation(payload)
      .then(res => {
        const resData = extractResponse(res).item;
        onSuccess?.(resData);
      })
      .catch(err => {
        toasts.send({
          message: get(t)('errors.unknown'),
          type: 'error',
        });

        onError?.(err);
      });
  }

  public removeSingleLinkFile({
    payload,
    onSuccess,
    onError,
  }: IServiceData<{
    id: string;
    permalink: string;
  }>) {
    linkApi
      .deleteSingleFile(payload)
      .then(res => {
        onSuccess?.(res);
      })
      .catch(err => {
        toasts.send({
          message: get(t)('errors.unknown'),
          type: 'error',
        });
        onError?.(err);
      });
  }

  public removeAllLinkFiles({ payload, onSuccess, onError }: IServiceData<string>) {
    linkApi
      .deleteAllFiles(payload)
      .then(res => {
        onSuccess?.(res);
      })
      .catch(err => {
        toasts.send({
          message: get(t)('errors.unknown'),
          type: 'error',
        });
        onError?.(err);
      });
  }

  public resetLink(): void {
    link.set({ ...linkInitValue });
  }

  public static instance: LinkService | null;

  static getInstance(): LinkService {
    if (!LinkService.instance) {
      LinkService.instance = new LinkService();
    }
    return LinkService.instance;
  }
}

export const linkService = LinkService.getInstance();
