import { get } from 'svelte/store';

import { t } from '@translation';

import { toasts } from '@shared/features';
import type { IContextData, IContextFile, IEmailData, IServiceData } from '@shared/models';
import { dataURLtoFile, generateEmailLinkHtml } from '@shared/utils';

class OfficeService {
  constructor() {}

  public getOfficeLanguage(): string {
    const officeLang = Office.context.displayLanguage;

    let lang = 'en';
    if (officeLang === 'de' || officeLang?.includes('de-')) {
      lang = 'de';
    }
    return lang;
  }

  public async addLinkToEmail({
    payload,
    onSuccess,
    onError,
  }: IServiceData<IEmailData>): Promise<void> {
    if (typeof Office.context.host === 'undefined') {
      toasts.send({
        message: get(t)('errors.office_connection_error'),
        type: 'error',
      });
      return;
    }

    const item = Office.context.mailbox.item;

    // Check if item exists and supports body
    if (item && item.body) {
      const htmlLink = await generateEmailLinkHtml(payload);

      item.body.setSelectedDataAsync(
        htmlLink,
        { coercionType: Office.CoercionType.Html },
        asyncResult => {
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            console.error('Error inserting link: ', asyncResult.error);
            // Optionally, add error handling here (like toasts)
            toasts.send({
              message: get(t)('errors.unknown'),
              type: 'error',
            });
            onError?.();
          } else {
            console.log('Link inserted successfully.');
            // Optionally, add success handling here (like toasts)
            onSuccess?.();
          }
        },
      );
    }
  }

  public handleInitializationContext({ onSuccess, onError }: Partial<IServiceData<void>>): void {
    if (!Office.context.mailbox?.item) {
      onError?.();
      return;
    }

    Office.context.mailbox?.item?.getInitializationContextAsync(asyncResult => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        const contextData: IContextData = JSON.parse(asyncResult.value || '{}');

        if (contextData.files) {
          const files = contextData.files.map((item: IContextFile) =>
            dataURLtoFile(`data:text/plain;${item.format},${item.content}`, item.name),
          );
          onSuccess?.(files);
        } else {
          onSuccess?.([]);
        }

        if (contextData.isParseError) {
          toasts.send({
            message: get(t)('errors.office_file_parsing_error'),
            type: 'error',
          });
          onError?.();
        }

        if (contextData.notificationIds?.length) {
          Office.context.mailbox.item?.notificationMessages.getAllAsync(asyncResult => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
              return;
            }

            if (asyncResult.value.length) {
              contextData.notificationIds.forEach(id =>
                Office.context.mailbox.item?.notificationMessages.removeAsync(id),
              );
            }
          });
        }
      }
    });
  }

  public removeEmailAttachedFiles({
    onSuccess,
    onError,
  }: Partial<IServiceData<void>> | void = {}): void {
    Office.context.mailbox?.item?.getInitializationContextAsync(asyncResult => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        const contextData: IContextData = JSON.parse(asyncResult.value || '{}');

        if (contextData.files) {
          contextData.files.forEach(file => {
            if (!file.isInline) {
              Office.context.mailbox.item?.removeAttachmentAsync(
                file.id,
                { asyncContext: null },
                asyncResult => {
                  if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                    toasts.send({
                      message: get(t)('errors.office_file_removal_error'),
                      type: 'error',
                    });
                    onError?.();
                  }
                },
              );
            }
          });
          onSuccess?.();
        } else {
          onError?.();
        }
      }
    });
  }

  // Singleton pattern for OfficeService instance
  public static instance: OfficeService | null;

  static getInstance(): OfficeService {
    if (!OfficeService.instance) {
      OfficeService.instance = new OfficeService();
    }
    return OfficeService.instance;
  }
}

export const officeService = OfficeService.getInstance();
