<script lang="ts">
  import { t } from '@translation';

  import { Path, router } from '@core/router';

  import { Button } from '@shared/components';
  import { Header, HeaderType } from '@shared/features';
  import { activeProfile } from '@shared/stores';

  import { ProfileList } from './components';
  import { getCompTranslateKey } from './translations';

  const handleAddAccount = (): void => {
    router.navigate(Path.LOGIN);
  };

  $: headerProps = {
    type: HeaderType.NON_USER,
    actionCallback: () => {
      router.navigate(Path.FILES);
    },
  };
</script>

{#if $activeProfile}
  <Header props={headerProps} />
{/if}

<div data-component="Profiles" class="wrapper">
  <ProfileList />

  <Button type="button" model="primary" size="medium" onClick={handleAddAccount}>
    {$t(getCompTranslateKey('add'))}
  </Button>
</div>

<style src="./styles.scss"></style>
