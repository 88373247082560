import type { IProfile } from '@shared/models';

type TProfileGroup = Record<string, IProfile[]>;

export const getProfileGroups = (profiles: IProfile[]): TProfileGroup =>
  profiles.reduce((acc: TProfileGroup, item: IProfile) => {
    if (acc[item.email]) {
      acc[item.email].push(item);
    } else {
      acc[item.email] = [item];
    }
    return acc;
  }, {});
