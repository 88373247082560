import { api, type TApiResponse } from '@core/api';

import { type IProfile } from '@shared/models';

class LoginApi {
  constructor() {}

  public getMe(token: string): Promise<TApiResponse<IProfile>> {
    return api.get('/general/whoami', {
      headers: {
        Authorization: token,
      },
    });
  }

  public static instance: LoginApi | null;

  static getInstance(): LoginApi {
    if (!LoginApi.instance) {
      LoginApi.instance = new LoginApi();
    }
    return LoginApi.instance;
  }
}

export const loginApi = LoginApi.getInstance();
