<script lang="ts">
  import { HeaderNonUser, HeaderUser } from './components';
  import { HeaderType } from './constants';
  import { type IHeaderState } from './models';

  export let props: IHeaderState;
</script>

<div data-component="Header" class="wrapper">
  {#if props.type === HeaderType.USER}
    <HeaderUser />
  {:else}
    <HeaderNonUser onCancel={props.actionCallback} />
  {/if}
</div>

<style src="./styles.scss"></style>
