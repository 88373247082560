import { type ILink } from '../models';

import { LinkType } from './linkType';

export const linkInitValue: ILink = {
  title: '',
  password: '',
  message: '',
  permalink: '',
  expiresIn: 7,
  emailNotification: false,
  downloadLimit: true,
  linkType: LinkType.SHARE,
};
