<script lang="ts">
  export let percentage = 0;
  export let color = '--green-200';
  export let size = 28;
  export let strokeWidth = 3;

  $: radius = (size - strokeWidth) / 2;
  $: dashArray = radius * Math.PI * 2;
  $: dashOffset = dashArray - (dashArray * percentage) / 100;
</script>

<svg
  width={size}
  height={size}
  viewBox={`0 0 ${size} ${size}`}
  data-component="ProgressRing"
  class="icon"
>
  <circle
    class="circle-background"
    cx={size / 2}
    cy={size / 2}
    r={radius}
    stroke-width={`${strokeWidth}px`}
  />
  <circle
    class="circle-progress"
    cx={size / 2}
    cy={size / 2}
    r={radius}
    stroke-width={`${strokeWidth}px`}
    transform={`rotate(-90 ${size / 2} ${size / 2})`}
    style={`stroke-dasharray: ${dashArray}; stroke-dashoffset: ${dashOffset}; stroke: var(${color});`}
  />
</svg>

<style src="./styles.scss" lang="scss"></style>
