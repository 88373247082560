export const getInitialFormErrors = (
  values: object,
): Partial<Record<keyof typeof values, string>> =>
  Object.keys(values).reduce(
    (acc, item) => ({
      ...acc,
      [item]: '',
    }),
    {},
  );
