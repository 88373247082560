<script lang="ts">
  import * as yup from 'yup';
  import { ValidationError } from 'yup';

  import { t } from '@translation';

  import { Path, router } from '@router';

  import { Button, InputText } from '@shared/components';
  import { authService } from '@shared/services';
  import { getFormErrors, getInitialFormErrors } from '@shared/utils';

  import { getCompTranslateKey } from './translations';

  let isLoading = false;

  const values = {
    token: '',
  };

  let errors = getInitialFormErrors(values);

  const schema = yup.object().shape({
    token: yup.string().required($t('validation.required')),
  });

  const handleSubmit = (): void => {
    errors = {};

    schema
      .validate(values, { abortEarly: false })
      .then(() => {
        isLoading = true;
        authService.getMe({
          payload: values.token,
          onSuccess: () => router.navigate(Path.FILES),
          onError: () => (isLoading = false),
        });
      })
      .catch(err => (errors = getFormErrors(err as ValidationError)));
  };
</script>

<div data-component="Login/LoginForm">
  <form on:submit|preventDefault={handleSubmit} class="form">
    <InputText
      bind:value={values.token}
      name="token"
      error={errors?.token}
      label={$t(getCompTranslateKey('token'))}
    />

    <Button type="submit" model="primary" size="medium" disabled={isLoading} {isLoading}>
      <span>{$t(getCompTranslateKey('submit'))}</span>
    </Button>
  </form>
</div>

<style src="./styles.scss"></style>
