import { getTranslatePath, translations } from '@translation';

const MODULE_TRANSLATION_KEY = 'modules/welcome';

const data = {
  en: {
    intro:
      'Effortlessly transfer files securely from your Outlook inbox. Simplify your workflow and protect your data.',
    dnd: 'Drag and drop your files to create instant share links',
    control: 'Control access to your shared files using customizable security settings',
    security: 'High security standards like ISO27001 Hosting and DSGVO compliance',
    login: 'Log in',
  },
  de: {
    intro:
      'Übertragen Sie mühelos und sicher Dateien aus Ihrem Outlook-Posteingang. Vereinfachen Sie Ihren Arbeitsablauf und schützen Sie Ihre Daten.',
    dnd: 'Ziehen Sie Ihre Dateien per Drag & Drop, um sofortige Freigabelinks zu erstellen',
    control:
      'Kontrollieren Sie den Zugriff auf Ihre freigegebenen Dateien mit anpassbaren Sicherheitseinstellungen',
    security: 'Hohe Sicherheitsstandards wie ISO27001 Hosting und DSGVO-Konformität',
    login: 'Einloggen',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
