<script lang="ts">
  import { onMount } from 'svelte';

  import { Icon } from '@shared/components';
  import { type IToast } from '@shared/components/Toast';

  let progressBarDuration = 0;

  export let id: IToast['id'];
  export let type: IToast['type'] = 'info';
  export let message: IToast['message'] = '';
  export let duration: IToast['duration'] = 3000;
  export let onClose: ((id: number) => void) | null = null;

  onMount(() => {
    progressBarDuration = Math.ceil(duration / 1000);
  });

  const getIconName = (): string => {
    switch (type) {
      case 'success': {
        return 'checkMark';
      }
      case 'warning': {
        return 'exclamationMark';
      }
      case 'error': {
        return 'dangerMark';
      }
      default:
        return 'infoMark';
    }
  };

  const getIconColor = (): string => {
    switch (type) {
      case 'success': {
        return '--green-100';
      }
      case 'warning': {
        return '--orange-100';
      }
      case 'error': {
        return '--red-100';
      }
      default:
        return '--blue-100';
    }
  };
</script>

<div data-component="Toast" class="toast">
  <div class="content-wrapper">
    <div class="content">
      <Icon name={getIconName()} color={getIconColor()} size={32} />

      <div class="message">
        {@html message}
      </div>
    </div>

    {#if onClose}
      <button class="close" type="button" on:click={() => onClose?.(id)}>
        <Icon name="close" size={18} />
      </button>
    {/if}
  </div>

  <div class="progress-bar">
    <div class={`bar ${type}`} style={`animation-duration: ${progressBarDuration}s;`} />
  </div>
</div>

<style src="./styles.scss" lang="scss"></style>
