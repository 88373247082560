<script lang="ts">
  export let label: string;
  export let name: string;
  export let checked: boolean;
  export let disabled = false;
  export let error = '';

  const handleChange = (): void => {
    checked = !checked;
  };
</script>

<label class="container" data-component="Checkbox">
  {label}

  <input
    type="checkbox"
    {name}
    {disabled}
    class="input"
    class:error={!!error}
    checked={!!checked}
    on:change={handleChange}
  />

  <span class="checkmark"></span>
</label>

<style src="./styles.scss" lang="scss"></style>
