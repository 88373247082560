const acceptFileTypes = [
  'bmp',
  'csv',
  'dat',
  'db',
  'dlk',
  'doc',
  'docm',
  'docx',
  'dotx',
  'dtd',
  'dvsdrw',
  'dvsdwpa',
  'dvsdwpd',
  'dvsdwpg',
  'eml',
  'fib',
  'gif',
  'htm',
  'html',
  'ila',
  'jpeg',
  'jpg',
  'lnk',
  'log',
  'mdb',
  'mde',
  'mp',
  'msg',
  'ods',
  'odt',
  'pdf',
  'png',
  'pptx',
  'rar',
  'rtf',
  'stasam',
  'svg',
  'tax',
  'tif',
  'tiff',
  'txt',
  'unl',
  'xls',
  'xlsb',
  'xlsm',
  'xlsx',
  'xltx',
  'xml',
  'z',
  'zip',
  '7z',
];

export const isFileTypeAcceptable = (filename: string): boolean => {
  if (!filename) return false;

  const extension = filename.split('.').pop()?.toLowerCase() || '';
  return acceptFileTypes.includes(extension);
};
