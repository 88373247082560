<script lang="ts">
  import { onMount } from 'svelte';

  import { Header, HeaderType } from '@shared/features';
  import { contextFiles } from '@shared/stores';

  import { Dropzone, FilesList, LinkInfoForm } from './components';
  import { linkService, uploadFilesService } from './services';
  import { uploadingFiles } from './stores';

  onMount(() => {
    uploadFilesService.setupUppy();

    // necessary to reset context files after
    // 1st render to render empty dropzone if
    // user decided not to add a link instead
    // of attached to email files
    if ($contextFiles.length) {
      const uniqueContextFiles = $contextFiles.filter(
        (item, i, arr) => i === arr.findIndex(val => val.name === item.name),
      );
      uploadFilesService.uploadFilesToCache(uniqueContextFiles);
    }
  });

  $: headerProps = {
    type: $uploadingFiles.length ? HeaderType.NON_USER : HeaderType.USER,
    actionCallback: $uploadingFiles.length
      ? () => {
          uploadFilesService.resetFiles();
          contextFiles.set([]);
        }
      : () => {},
  };

  $: if (!$uploadingFiles.length) {
    linkService.resetLink();
  }
</script>

<Header props={headerProps} />

<div data-component="Files" class="wrapper">
  <Dropzone />

  {#if !!$uploadingFiles.length}
    <FilesList />
    <LinkInfoForm />
  {/if}
</div>

<style src="./styles.scss"></style>
