import { getTranslatePath, translations } from '@translation';

const MODULE_TRANSLATION_KEY = 'modules/files/link_info_form';

const data = {
  en: {
    done: 'Done',
    title: 'Title',
    note: 'Note',
    waiting_upload: 'Waiting for file upload.',
    waiting_action: 'You can already edit link settings.',
    uploaded_successfully: 'Your files uploaded successfully.',
    unlimited_downloads: 'Unlimited downloads',
    two_way: 'Allow two-way file sharing',
    notifications: 'Receive e-mail notifications',
    tooltip_download:
      'By default, recipients can download files only once. Enable "Unlimited Downloads" to allow multiple downloads.',
    tooltip_two_way:
      'Send and receive files using the same link. The Double-Opt-In security ensures files are shared with verified email addresses.',
    tooltip_notifications:
      'Receive email updates on file activity. You can unsubscribe from notifications in settings anytime.',
  },
  de: {
    done: 'Erledigt',
    title: 'Titel',
    note: 'Hinweis',
    waiting_upload: 'Wartet auf Datei-Upload.',
    waiting_action: 'Sie können die Linkeinstellungen bereits bearbeiten.',
    uploaded_successfully: 'Ihre Dateien wurden erfolgreich hochgeladen.',
    unlimited_downloads: 'Unbegrenzte Downloads',
    two_way: 'Empfangen von Dateien erlauben',
    notifications: 'E-Mail-Benachrichtigungen erhalten',
    tooltip_download:
      'Standardmäßig können die Empfänger Dateien nur einmal herunterladen. Aktivieren Sie „Unbegrenzte Downloads“, um mehrere Downloads zu ermöglichen.',
    tooltip_two_way:
      'Senden und empfangen Sie Dateien über denselben Link. Das Double-Opt-In stellt sicher, dass Dateien nur über verifizierte E-Mail-Adressen geteilt werden.',
    tooltip_notifications:
      'Erhalten Sie E-Mail-Benachrichtigungen über Dateiaktivitäten. Sie können die Benachrichtigungen in den Einstellungen jederzeit abbestellen.',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
