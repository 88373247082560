<script lang="ts">
  import { t } from '@translation';

  import { Icon } from '../Icon';

  export let value: string;
  export let name: string;
  export let label: string = '';
  export let error: string = '';
  export let autocomplete: 'off' | 'on' = 'off';
  export let disabled: boolean = false;
  export let isOptional = false;
</script>

<div data-component="InputText" class="wrapper">
  {#if label}
    <span class="label">
      {label}
      {#if isOptional}
        <span class="label-info">({$t('control.optional')})</span>
      {/if}
    </span>
  {/if}

  <input bind:value {name} {disabled} {autocomplete} class="input" class:error={!!error} />

  {#if error}
    <div class="error-wrapper">
      <Icon name="exclamationMark" color="--red-300" size={14} />
      <span class="error-msg">{error}</span>
    </div>
  {/if}
</div>

<style src="./styles.scss" lang="scss"></style>
