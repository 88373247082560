import { getTranslatePath, translations } from '@translation';

const MODULE_TRANSLATION_KEY = 'modules/files/files_list';

const data = {
  en: {
    files: '(1)[{{count}} file];(2-inf)[{{count}} files]',
    delete_all_files: 'Delete all files',
  },
  de: {
    files: '(1)[{{count}} Datei];(2-inf)[{{count}} Dateien]',
    delete_all_files: 'Alle Dateien löschen',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
