<script lang="ts">
  import generator from 'generate-password-browser';

  import { t } from '@translation';

  import { Button } from '../Button';
  import { InputText } from '../InputText';

  import { getCompTranslateKey } from './translations';

  export let value: string;
  export let name: string;
  export let error: string = '';
  export let disabled: boolean = false;
  export let isOptional = false;

  const handleGeneratePassword = (): void => {
    value = generator.generate({ length: 16, numbers: true });
  };
</script>

<div data-component="InputPassword" class="wrapper">
  <div class="label-wrapper">
    <span class="label">
      {$t(getCompTranslateKey('password'))}
      {#if isOptional}
        <span class="label-info">({$t('control.optional')})</span>
      {/if}
    </span>

    <Button type="button" model="primary-text" size="extra-small" onClick={handleGeneratePassword}>
      {$t(getCompTranslateKey('generate_password'))}
    </Button>
  </div>

  <InputText bind:value {name} {error} {disabled} />
</div>

<style src="./styles.scss" lang="scss"></style>
