// FIlE ICONS --start
import arrowIconPath from '@icons/arrow.svg';
import checkMarkIconPath from '@icons/checkmark.svg';
import closeIconPath from '@icons/close.svg';
import complianceIconPath from '@icons/compliance.svg';
import dangerMarkIconPath from '@icons/danger-mark.svg';
// FIlE ICONS --end
import dragAndDropIconPath from '@icons/drag-and-drop.svg';
import exclamationMarkIconPath from '@icons/exclamation-mark.svg';
import documentIconPath from '@icons/fileTypes/document.svg';
import excelIconPath from '@icons/fileTypes/excel.svg';
import fileIconPath from '@icons/fileTypes/file.svg';
import imageIconPath from '@icons/fileTypes/image.svg';
import pdfIconPath from '@icons/fileTypes/pdf.svg';
import powerpointIconPath from '@icons/fileTypes/powerpoint.svg';
import tableIconPath from '@icons/fileTypes/table.svg';
import wordIconPath from '@icons/fileTypes/word.svg';
import zipIconPath from '@icons/fileTypes/zip.svg';
import infoMarkIconPath from '@icons/info-mark.svg';
import infoOutlinedIconPath from '@icons/info-outlined.svg';
import lockIconPath from '@icons/lock.svg';
import trashIconPath from '@icons/trash.svg';

export const icons: Record<string, string> = {
  // FIlE ICONS --start
  documentType: documentIconPath,
  excelType: excelIconPath,
  fileType: fileIconPath,
  imageType: imageIconPath,
  pdfType: pdfIconPath,
  powerpointType: powerpointIconPath,
  tableType: tableIconPath,
  wordType: wordIconPath,
  zipType: zipIconPath,
  // FIlE ICONS --end

  dragAndDrop: dragAndDropIconPath,
  lock: lockIconPath,
  compliance: complianceIconPath,
  arrow: arrowIconPath,
  checkMark: checkMarkIconPath,
  dangerMark: dangerMarkIconPath,
  exclamationMark: exclamationMarkIconPath,
  infoMark: infoMarkIconPath,
  close: closeIconPath,
  trash: trashIconPath,
  infoOutlined: infoOutlinedIconPath,
};
