import { api, type TApiResponse } from '@core/api';

import { type IFileMeta, type ILink, type ILinkFile, type TLinkFileStatused } from '../models';

class LinkApi {
  constructor() {}

  public createLink(data: ILink): Promise<TApiResponse<ILink>> {
    return api.post('/links', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  public addLinkFile(data: { fileMeta: IFileMeta; id: string }): Promise<TApiResponse<ILinkFile>> {
    return api.post(`/links/${data.id}/files`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  public finalizeLinkCreation(data: ILink): Promise<TApiResponse<ILink>> {
    const { permalink, ...payload } = data;
    return api.patch(`/links/${permalink}`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  public getLinkFilesStatus(data: {
    id: string;
    ids: string[];
  }): Promise<TApiResponse<TLinkFileStatused>> {
    const { id, ids } = data;

    return api.get(`/links/${id}/files/status`, {
      params: { ids },
    });
  }

  public deleteSingleFile(data: { id: string; permalink: string }): Promise<TApiResponse<void>> {
    const { id, permalink } = data;

    return api.delete(`links/${permalink}/files/${id}`);
  }

  public deleteAllFiles(permalink: string): Promise<TApiResponse<void>> {
    return api.delete(`links/${permalink}/files`);
  }

  public static instance: LinkApi | null;

  static getInstance(): LinkApi {
    if (!LinkApi.instance) {
      LinkApi.instance = new LinkApi();
    }
    return LinkApi.instance;
  }
}

export const linkApi = LinkApi.getInstance();
