<script lang="ts">
  export let color = '--blue-100';
  export let size = 40;
  export let strokeWidth = 6;

  const percentage = 25;
  $: radius = (size - strokeWidth) / 2;
  $: dashArray = radius * Math.PI * 2;
  $: dashOffset = dashArray - (dashArray * percentage) / 100;
</script>

<svg
  width={size}
  height={size}
  viewBox={`0 0 ${size} ${size}`}
  data-component="LoadingSpinner"
  class="spinner"
>
  <circle
    class="circle-background"
    cx={size / 2}
    cy={size / 2}
    r={radius}
    stroke-width={`${strokeWidth}px`}
  />
  <circle
    class="circle-progress"
    cx={size / 2}
    cy={size / 2}
    r={radius}
    stroke-width={`${strokeWidth}px`}
    style={`stroke-dasharray: ${dashArray}; stroke-dashoffset: ${dashOffset}; stroke: var(${color});`}
  />
</svg>

<style src="./styles.scss" lang="scss"></style>
