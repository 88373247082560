<script lang="ts">
  import { beforeUpdate, onMount } from 'svelte';
  import { createEventDispatcher } from 'svelte';

  import { clickOutside } from '@shared/actions';

  export let contentWidth: number | 'fit-content' = 0;
  export let isCloseOnSelection = false;

  let triggerRef: HTMLElement;

  const dispatch = createEventDispatcher();

  $: stylesPositionData = {
    vertical: {
      direction: 'bottom',
      position: 0,
    },
    horizontal: {
      direction: 'left',
      position: 0,
    },
  };

  $: isOpen = false;
  $: isOpen,
    dispatch('toggle', {
      isOpen,
    });

  let optionsRef: HTMLElement;

  const getPositionCalculation = (): void => {
    const positionData = triggerRef!.getBoundingClientRect();
    const { bottom, top, left, right } = positionData;

    const { innerHeight, innerWidth } = window;

    const fromBottomToItem = innerHeight - bottom;
    const verticalDirection = fromBottomToItem < top ? 'bottom' : 'top';
    const gap = 8;
    const yPosition = verticalDirection === 'top' ? bottom + gap : innerHeight - top + gap;

    const horizontalScrollSpace = 20;
    const horizontalDirection =
      left + optionsRef.getBoundingClientRect().width + horizontalScrollSpace > innerWidth
        ? 'right'
        : 'left';
    const xPosition = horizontalDirection === 'right' ? innerWidth - right : left;

    stylesPositionData = {
      vertical: {
        direction: verticalDirection,
        position: yPosition,
      },
      horizontal: {
        direction: horizontalDirection,
        position: xPosition,
      },
    };
  };

  const getDropdownStyles = (): string => {
    const { vertical, horizontal } = stylesPositionData;
    return `${vertical.direction}: ${vertical.position}px; ${horizontal.direction}: ${horizontal.position}px`;
  };

  const handleClose = (): void => {
    isOpen = false;
  };

  const toggleOpen = (): void => {
    isOpen = !isOpen;
  };

  const getContentWidth = (): string =>
    contentWidth === 'fit-content'
      ? contentWidth
      : contentWidth
        ? contentWidth + 'px'
        : triggerRef.getBoundingClientRect().width + 'px';

  onMount(() => {
    document.addEventListener('scroll', () => {
      handleClose();
    });

    addEventListener('resize', () => {
      handleClose();
    });
  });

  beforeUpdate(() => {
    if (triggerRef && optionsRef) {
      getPositionCalculation();
    }
  });
</script>

<div data-component="Dropdown" on:outside={handleClose} use:clickOutside class="wrapper">
  <div
    bind:this={triggerRef}
    on:click={toggleOpen}
    on:keydown={toggleOpen}
    class="trigger-wrapper"
    role="button"
    tabindex="-1"
  >
    <slot name="trigger" />
  </div>

  {#if isOpen}
    <div
      style={`${stylesPositionData ? getDropdownStyles() : 'display: none'}; width: ${getContentWidth()}`}
      class="options-wrapper"
      bind:this={optionsRef}
    >
      <div
        class="list-wrapper"
        on:click={isCloseOnSelection ? handleClose : () => {}}
        on:keydown={handleClose}
        role="button"
        tabindex="-1"
      >
        <slot name="content" />
      </div>
    </div>
  {/if}
</div>

<style src="./styles.scss" lang="scss"></style>
