import type { Action } from 'svelte/action';

type TAttributes = {
  'on:outside'?: (event: CustomEvent) => void;
};
type TClickOutsideAction = Action<HTMLElement, any, TAttributes>;

export const clickOutside: TClickOutsideAction = (
  el: HTMLElement,
): {
  destroy: () => void;
} => {
  function handleClick(event: MouseEvent) {
    const targetEl = event.target as HTMLElement;

    if (el && !el.contains(targetEl)) {
      const clickOutsideEvent = new CustomEvent('outside');
      el.dispatchEvent(clickOutsideEvent);
    }
  }

  document.addEventListener('click', handleClick, true);

  return {
    destroy() {
      document.removeEventListener('click', handleClick, true);
    },
  };
};
