import { getTranslatePath, translations } from '@translation';

const MODULE_TRANSLATION_KEY = 'modules/profiles/profile_list';

const data = {
  en: {
    users_found: 'Users found for ',
  },
  de: {
    users_found: 'Benutzer gefunden für ',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
