<script lang="ts">
  import { t } from '@translation';

  import { Path, router } from '@router';

  import { Button, Icon } from '@shared/components';

  import { getCompTranslateKey } from './translations';

  const handleLogIn = (): void => {
    router.navigate(Path.LOGIN);
  };
</script>

<div data-component="Welcome" class="wrapper">
  <p class="text">{$t(getCompTranslateKey('intro'))}</p>

  <div class="info">
    <div class="info-item">
      <Icon name="dragAndDrop" color="--primary-500" size={28} />
      <span class="text">
        {$t(getCompTranslateKey('dnd'))}
      </span>
    </div>

    <div class="info-item">
      <Icon name="lock" color="--primary-500" size={28} />
      <span class="text">
        {$t(getCompTranslateKey('control'))}
      </span>
    </div>

    <div class="info-item">
      <Icon name="compliance" color="--primary-500" size={28} />
      <span class="text">
        {$t(getCompTranslateKey('security'))}
      </span>
    </div>
  </div>

  <Button type="button" model="primary" size="medium" onClick={handleLogIn}>
    {$t(getCompTranslateKey('login'))}
  </Button>
</div>

<style src="./styles.scss"></style>
