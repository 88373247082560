import {
  type Invalidator,
  type Subscriber,
  type Unsubscriber,
  type Writable,
  writable,
} from 'svelte/store';

import { type IToast } from '@shared/components/Toast';

interface IToastSendData {
  message: string;
  type: 'info' | 'success' | 'warning' | 'error';
  isClose?: boolean;
  duration?: number;
  additionalLineDuration?: number;
}

interface IToastStore {
  subscribe: (this: void, run: Subscriber<any[]>, invalidate?: Invalidator<any[]>) => Unsubscriber;
  close: (arg: number) => void;
  send: (arg: IToastSendData) => void;
}

const createToastStore = (): IToastStore => {
  const toasts: Writable<IToast[]> = writable([]);

  function send({
    message = '',
    type = 'info',
    isClose = true,
    duration = 3000,
    additionalLineDuration = 1000,
  }: IToastSendData) {
    let toast: IToast = {
      id: new Date().getTime(),
      type,
      message,
      isClose,
      duration,
    };

    if (toast.message) {
      let rows = Math.max(Math.ceil(toast.message.length / 35), 4);
      toast.duration += rows * additionalLineDuration;
    }

    toasts.update(state => {
      return [toast, ...state];
    });

    setTimeout(() => {
      removeToast(toast.id);
    }, toast.duration);
  }

  const close = (toastId: number): void => {
    removeToast(toastId);
  };

  const removeToast = (toastId: number): void => {
    toasts.update(state => {
      return [...state.filter(toast => toast.id !== toastId)];
    });
  };

  const { subscribe } = toasts;

  return {
    subscribe,
    close,
    send,
  };
};

export const toasts = createToastStore();
