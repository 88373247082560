<script lang="ts">
  import { t } from '@translation';

  import { Button, Icon, ProgressRing, Tooltip } from '@shared/components';
  import { formatBites } from '@shared/utils';

  import type { IUploadingFile } from '../../models';
  import { linkService, uploadFilesService } from '../../services';
  import { link } from '../../stores';
  import { getFileIconName } from '../../utils';

  import { getCompTranslateKey } from './translations';

  export let file: IUploadingFile;

  const onRemove = (): void => {
    if (file.progress.uploadComplete) {
      const payload = {
        id: file.permalink,
        permalink: $link.permalink,
      };

      linkService.removeSingleLinkFile({ payload });
    }

    uploadFilesService.removeFile(file.cacheId);
  };

  $: uploadProgress = file.progress.percentage;
</script>

<div data-component="Files/FilesListItem" class="wrapper">
  <div class="icon-wrapper">
    {#if file.progress.uploadStarted && !file.progress.uploadComplete}
      <ProgressRing percentage={uploadProgress} />
    {:else if file.progress.uploadComplete && file.virus}
      <Icon size={22} name="dangerMark" color="--red-100" />
    {:else if file.progress.uploadComplete}
      <Icon size={28} name={getFileIconName(file.name)} />
    {/if}
  </div>

  <div class="content">
    <div class="info">
      <Tooltip placement="top" isOverflowProtection={true} message={file.name}>
        <span class="title">{file.name}</span>
      </Tooltip>

      <span class="descr">
        {#if !file.stored && file.progress.uploadComplete}
          {$t(getCompTranslateKey('checking'))}
        {:else if file.stored && file.virus}
          {$t(getCompTranslateKey('virus_found'))}
        {:else}
          {`(${formatBites(file.size)})`}
        {/if}
      </span>
    </div>

    <Button
      onClick={onRemove}
      model="error-text"
      contentType="icon"
      disabled={!$link.permalink}
      isLoading={!$link.permalink}
    >
      <Icon size={18} name="trash" color="--red-100" />
    </Button>
  </div>
</div>

<style src="./styles.scss"></style>
