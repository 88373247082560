<script lang="ts">
  import { t } from '@translation';

  import { Button } from '@shared/components';

  import { linkService, uploadFilesService } from '../../services';
  import { link, uploadingFiles } from '../../stores';
  import { FilesListItem } from '../FilesListItem';

  import { getCompTranslateKey } from './translations';

  export const handleRemoveAll = (): void => {
    uploadFilesService.removeAllUppyFiles();
    linkService.removeAllLinkFiles({ payload: $link.permalink });
  };
</script>

<div data-component="Files/FilesList">
  <div class="head">
    <div class="title">
      {$t(getCompTranslateKey('files'), {
        postProcess: 'interval',
        count: $uploadingFiles?.length,
      })}
    </div>

    {#if $uploadingFiles.length}
      <Button type="button" onClick={handleRemoveAll} model="error-text" size="small">
        {$t(getCompTranslateKey('delete_all_files'))}
      </Button>
    {/if}
  </div>

  <div class="list">
    {#each $uploadingFiles as file (file.name)}
      <FilesListItem {file} />
    {/each}
  </div>
</div>

<style src="./styles.scss"></style>
