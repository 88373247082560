<script lang="ts">
  // TODO use as reference for dropdown control component and move to shared
  //  in case of adding another dropdown control to the project

  import { t } from '@translation';

  import { Dropdown, Icon } from '@shared/components';

  import { getCompTranslateKey } from './translations';

  export let name: string;
  export let value: number;

  $: isActive = false;

  const expireInDays = [1, 3, 7, 14, 30];

  const handleSelect = (item: number): void => {
    value = item;
  };
</script>

<div data-component="Files/LinkInfoForm/ExpiresInDropdown" class="wrapper">
  <span class="label">{$t(getCompTranslateKey('expires_after'))}</span>

  <Dropdown isCloseOnSelection={true} on:toggle={e => (isActive = e.detail.isOpen)}>
    <button slot="trigger" type="button" class="trigger" class:trigger-active={isActive}>
      <input
        type="text"
        {name}
        value={$t(getCompTranslateKey('day'), {
          postProcess: 'interval',
          count: value,
        })}
        disabled
        class="input"
      />
      <input type="hidden" {name} bind:value />

      <span class="expand" class:expand-active={isActive}>
        <Icon name="arrow" color="--blue-100" size={20} />
      </span>
    </button>

    <svelte:fragment slot="content">
      {#each expireInDays as item}
        <button class="item" type="button" on:click={() => handleSelect(item)}>
          {$t(getCompTranslateKey('day'), {
            postProcess: 'interval',
            count: item,
          })}
        </button>
      {/each}
    </svelte:fragment>
  </Dropdown>
</div>

<style src="./styles.scss"></style>
