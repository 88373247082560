import { derived, get, type Writable, writable } from 'svelte/store';

import i18next from 'i18next';
import type { Resource } from 'i18next/typescript/options';
import * as intervalPlural from 'i18next-intervalplural-postprocessor';

import translationsDE from './translations/de.json';
import translationsEN from './translations/en.json';

// STORES
const t: Writable<
  ((_: string, config?: Record<string, string | number>) => string) | (() => string)
> = writable(() => '');

const translationDB = writable({
  en: { translation: translationsEN },
  de: { translation: translationsDE },
} as Resource);

const currentLanguage = derived(t, (_, set) => {
  set(i18next.language);
});

const changeLanguage = (newLanguage: string) => {
  i18next.changeLanguage(newLanguage, (err, newT: (key: string) => string) => {
    if (err) return console.log('something went wrong loading', err);
    t.set(newT);
  });
};

// HELPERS
const i18nInitialize = async (language: string): Promise<void> => {
  i18next.on('missingKey', (_, namespace: string, key: string) => {
    if (window.location.href.includes('localhost')) {
      console.error(`TranslationMissingError: ${[language, namespace, key].join('.')}`);
    }
  });

  return i18next
    .use(intervalPlural)
    .init({
      lng: language,
      debug: false,
      resources: get(translationDB),
      saveMissing: true,
    })
    .then(_t => t.set(_t));
};

const translations = (
  translationKey: string,
  translationData: Record<string, Record<string, string>>,
): void => {
  const getUpdatedLocale = <T>(lang: string, prev: T): Resource => ({
    [lang]: {
      translation: {
        ...prev,
        [translationKey]: translationData[lang],
      },
    },
  });

  translationDB.update(prev => ({
    ...getUpdatedLocale('en', prev.en.translation),
    ...getUpdatedLocale('de', prev.de.translation),
  }));
};

export { changeLanguage, currentLanguage, i18nInitialize, t, translations };
