import { getTranslatePath, translations } from '@translation';

const MODULE_TRANSLATION_KEY = 'shared/input_password';

const data = {
  en: {
    password: 'Password',
    generate_password: 'Generate password',
  },
  de: {
    password: 'Passwort',
    generate_password: 'Passwort generieren',
  },
};

translations(MODULE_TRANSLATION_KEY, data);

export const getCompTranslateKey: (key: string) => string =
  getTranslatePath(MODULE_TRANSLATION_KEY);
