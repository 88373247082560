<script lang="ts">
  import { fly } from 'svelte/transition';

  import { Toast } from '@shared/components/Toast';

  import { toasts } from './stores';

  const handleCloseToast = (id: number): void => {
    toasts.close(id);
  };
</script>

<div class="wrapper" data-component="Toasts">
  {#each $toasts as toast (toast.id)}
    <div transition:fly|global={{ x: 200, duration: 200 }}>
      <Toast {...toast} inline={false} onClose={handleCloseToast} />
    </div>
  {/each}
</div>

<style src="./styles.scss" lang="scss"></style>
