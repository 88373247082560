<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import { createPopperActions } from 'svelte-popperjs';

  export let placement: 'top' | 'bottom' | 'left' | 'right';
  export let size: 'small' | 'medium' = 'small';
  export let isOverflowProtection = false;
  export let message: string;

  let isOpen = false;

  const dispatch = createEventDispatcher();

  const [popperRef, popperContent] = createPopperActions({
    strategy: 'fixed',
    placement,
  });

  const extraOpts = {
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'flip', options: { fallbackPlacements: ['top', 'bottom'] } },
      {
        name: 'arrow',
        options: {
          padding: ({ popper, reference }: any) => popper.width / reference.width,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          altAxis: false,
          padding: 16,
        },
      },
    ],
  };

  const toggleOpen = (): void => {
    isOpen = !isOpen;
    dispatch('toggle', {
      isOpen,
    });
  };
</script>

<div class="wrapper" data-component="Tooltip">
  <div
    use:popperRef
    on:mouseenter={toggleOpen}
    on:mouseleave={toggleOpen}
    role="button"
    tabindex="0"
    class="trigger-wrapper"
    class:fit-content={!isOverflowProtection}
  >
    <slot />
  </div>

  {#if isOpen}
    <div use:popperContent={extraOpts} class={`tooltip-wrapper ${size}`}>
      <span class="message">{message}</span>
      <div class="arrow" data-popper-arrow></div>
    </div>
  {/if}
</div>

<style src="./styles.scss" lang="scss"></style>
